import './index.scss'
import { TimerModes } from './components/TimerModes'
import { TimerDisplay } from "./components/TimerDisplay"
import { TimerControls } from "./components/TimerControls"
import { TaskList } from './components/TaskList'
import Loader from 'react-loaders'

import Container from '@mui/material/Container'



const Pomodoro = () => {



    return (

        <>



            <Container
                component="section"
                maxWidth="sm"
                sx={{
                    p: 2,
                    border: '2px solid green',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <TimerModes/>
                <TimerDisplay />
                <TimerControls/>
                <TaskList/>
            </Container>




            <Loader type='ball-pulse-sync' />




        </>



    )


}

export default Pomodoro


