//import LogoTitle from '../../assets/images/logo-s.png';
import { Link } from 'react-router-dom'
import './index.scss'
import FadeInLetters from '../FadeInLetters';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders'
import home_feature from "../../assets/images/Timeline 1.gif"





const Home = () => {
   
    const [letterClass, setLetterClass] = useState('text-animate')
    const greeting = ['H', 'i', ',', ' ']
    const introduction = ['M', 'y', ' ', 'N', 'a', 'm', 'e', ' ', 'i', 's',]
    const nameArray = ['S', 't', 'e', 'p', 'h', 'a', 'n', ' ', 'M', 'a', 'h']
    const jobArray = ['S', 'o', 'f', 't', 'w', 'a', 'r',
        'e', ' ', 'E', 'n', 'g', 'i', 'n', 'e', 'e', 'r']

    useEffect(() => {
        setTimeout(() => { setLetterClass('text-animate-hover') }, 4000)
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1> <FadeInLetters letterClass={letterClass}
                        strArray={greeting}
                        idx={15} />
                        <FadeInLetters letterClass={letterClass}
                            strArray={introduction}
                            idx={15} />
                        <br />

                        <FadeInLetters letterClass={letterClass}
                            strArray={nameArray}
                            idx={15} />

                        <h2>

                        </h2>
                        <FadeInLetters letterClass={letterClass}
                            strArray={jobArray}
                            idx={25}/>
                    </h1>
                    <Link to="/contact" className='flat-button'> Contact Me </Link>
                </div>
                {windowWidth > 480 && (
                    <div className='home-feature'>
                        <img src={home_feature} alt="programming art thing" />
                    </div>
                )}




            </div>
            <Loader type="ball-pulse-sync" />
        </>
    );
}





export default Home
