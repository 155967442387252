//Allows user to add list of tasks to complete and check off upon completion
import "../styles/taskList.scss"
export const TaskList = () => {
    return (
        <>
            <div className="task-list">

                <h1> TaskList</h1>
            </div>
        </>
    )
}

