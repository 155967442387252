//Start/Pause, Reset, and skip button
//import timerConfig from "../constants/timerConfig"
import "../styles/timerControls.scss"
export const TimerControls = () => {
    return (
        <>
            <div className="timer-controls">

                <h1> TimerControls</h1>
            </div>
        </>
    )

}

