//Timer display
import "../styles/timerDisplay.scss"


export const TimerDisplay = () => {
    return (
        <>
            <div className="timer-display">

                <h1> TimerDisplay</h1>
            </div>
        </>
    )

}

