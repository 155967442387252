import './index.scss'
import Loader from 'react-loaders'
import { Link } from 'react-router-dom'
import FadeInLetters from "../FadeInLetters";
import { useEffect, useState } from 'react';
import NBADA_Logo from '../../assets/images/nbada_logo.png';
import tbd_Placeholder from '../../assets/images/tbd.png';

const Projects = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
    }, [])

    return (
        <>
            <div className="container projects-page">
                <div className="text-zone">
                    <h1>
                        <FadeInLetters
                            letterClass={letterClass}
                            strArray={['P', 'r', 'o', 'j', 'e', 'c', 't', 's']}
                            idx={5}
                        />
                    </h1>
                </div>

                <div className="projects-box">
                    <div className='nba-data'>
                        <a href="https://nba-data-analyzer.streamlit.app/" target="_blank" rel="noreferrer">
                            <img className="nba-logo" src={NBADA_Logo} alt="nba data analyzer" />
                        </a>

                        <Link to="https://nba-data-analyzer.streamlit.app/" className="flat-button">
                            NBA Data Analyzer
                        </Link>

                        <p className='project-descs'>
                            NBA Data Analyzer is basic web app that allows you to quickly find stats from any current NBA player and team. <br /><br />
                            It is made using Python, Streamlit, and the NBA API. <br />
                            There is a good chance the app will be asleep when you visit, feel free to wake it up to take a look.
                        </p>
                    </div>

                    <div className='project4'>
                        <a href="/projects" target='_blank'> </a>
                        <img className="tbd-logo" src={tbd_Placeholder} alt="upcoming project" />
                        <Link to="" className="flat-button"> Unreal Engine 5 Project</Link>

                        <p className='project-descs'>
                            I'm currently working on on a top down shooter built in Unreal Engine 5, leading a small team of friends. The game features unique weapons, evolving enemies, and procedurally generated levels.
                        </p>
                    </div>
                </div>
            </div>
            <Loader type='ball-pulse-sync' />
        </>
    )
}

export default Projects