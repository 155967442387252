import Loader from 'react-loaders'
import './index.scss'
import FadeInLetters from '../FadeInLetters'
import { useState, useEffect, useRef } from 'react'
import emailjs  from '@emailjs/browser'


const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_fen186o', 'template_zgrr87k', form.current, { publicKey: 'tyyRrPmZyvHi8YIo1', }).then(
            () => {
                alert("Message sent successfully!");
                window.location.reload(true)

            },
            (error) => {
                alert("Failed to send message, try again");
            }
        )
    }




    return (

        <>
            <div className='container contact-page'>
                <div className='text-zone' >
                    <h1>
                        <FadeInLetters
                            letterClass={letterClass}
                            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
                            idx={5} />
                    </h1>
                    <h2>
                        Shoot me an email if you have any questions, suggestions, or want to get in touch with me.
                    </h2>
                    <div className='contact-form'>
                        <form ref={form} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input placeholder="Name" type="text" name="name" required />
                                </li>
                                <li className="half">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </li>
                                <li>
                                    <input
                                        placeholder="Subject"
                                        type="text"
                                        name="subject"
                                        required
                                    />
                                </li>
                                <li>
                                    <textarea
                                        placeholder="Message"
                                        name="message"
                                        required
                                    ></textarea>
                                </li>
                                <li>
                                    <input type="submit" className="flat-button" value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Loader type="ball-pulse-sync" />




        </>
    )
}

export default Contact